var appState = {};

export function setupInterface() {
  Object.assign(window, {
    interface: {
      getState(key) {
        return appState[key];
      },
      setState: (key, value) => {
        appState[key] = value;
        return window.interface.getState(key);
      },
    },
  });
}

export default appState;
